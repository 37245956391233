export const MOMENT_DICTIONARY = {
	dddd: 'EEEE',
	YYYY: 'yyyy',
	DD: 'dd',
	'HH:MM': 'HH:mm',
	'hh:mm A': 'hh:mm a',
	'DD/MM': 'dd/MM',
	'DD-MM': 'dd-MM',
	'DD/MM/YYYY': 'dd/MM/yyyy',
	'DD-MM-YYYY': 'dd-MM-yyyy',
	'DD-MM-YYYY HH:mm': 'dd-MM-yyyy HH:mm',
	'DD/MM/YYYY HH:mm': 'dd/MM/yyyy HH:mm',
	'DD/MM/YYYY HH:mm:ss': 'dd/MM/yyyy HH:mm:ss',
	'MM/DD/YYYY': 'MM/dd/yyyy',
	'MM/DD/YYYY HH:mm:ss': 'MM/dd/yyyy HH:mm:ss',
	'YYYY-MM-DD': 'yyyy-MM-dd',
	'YYYY/MM/DD': 'yyyy/MM/dd',
	'YYYY-MM-DD HH:MM': 'yyyy-MM-dd HH:mm',
	'YYYY/MM/DD HH:mm': 'yyyy-MM-dd HH:mm',
	'YYYY/MM/DD HH:ss': 'yyyy/MM/dd HH:ss',
	'L HH:mm:ss': 'MM/dd/yyyy HH:mm:ss',
	'dddd DD MMMM HH:mm': 'EEEE dd MMMM HH:mm',
	'dddd DD MMMM': 'EEEE dd MMMM'
};
