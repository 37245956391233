/* eslint-disable class-methods-use-this */
import IndexedDB from 'libs/IndexedDB';
import { isEmptyValue } from 'utils';

const storageConfig = {
	name: 'filtersStorage',
	version: 1,
	options: {
		objectStores: [{ name: 'filters', config: {} }]
	},
	transactionConfig: {
		objectStoreName: 'filters'
	}
};

class FiltersStorage {
	constructor() {
		const storage = IndexedDB.isAvailable() ? new IndexedDB(storageConfig) : null;
		this.storage = storage?.isValidInstance ? storage : null;
	}

	async getFiltersObj(viewKey) {
		if (!this.storage) return;
		try {
			const filtersObj = await this.storage.get(viewKey);
			return filtersObj;
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log({ error });
			return {};
		}
	}

	async getFilters(viewKey) {
		if (!this.storage) return {};
		try {
			const result = await this.getFiltersObj(viewKey);
			return result?.filters || {};
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log({ error });
			return {};
		}
	}

	removeUnavailableFiltersFromSchema(filtersToSave, schemaFilters) {
		Object.keys(filtersToSave).forEach(filter => {
			if (!schemaFilters.some(schemaFilter => schemaFilter.name === filter)) {
				// eslint-disable-next-line no-param-reassign
				delete filtersToSave[filter];
			}
		});
		return filtersToSave;
	}

	async insertFilters(viewKey, filtersToSave, isSingleFilter) {
		if (!this.storage) return;
		try {
			const filtersInStorage = await this.getFilters(viewKey);
			const newFiltersToSaveObj = {};

			Object.entries(filtersToSave).forEach(filter => {
				const [name, value] = filter;
				if (!value || isEmptyValue(value)) {
					delete filtersInStorage[name];
					return;
				}
				newFiltersToSaveObj[name] = value;
			});
			const filters = isSingleFilter
				? {
						...filtersInStorage,
						...newFiltersToSaveObj
				  }
				: newFiltersToSaveObj;
			await this.storage.set({
				id: viewKey,
				filters
			});
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log({ error });
		}
	}

	async clearFilter(viewKey, filterKey) {
		if (!this.storage) return;
		try {
			const filtersInStorage = await this.getFilters(viewKey);

			delete filtersInStorage[filterKey];

			await this.storage.set({ id: viewKey, filters: filtersInStorage });
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log({ error });
		}
	}

	async clearFilters(viewKey) {
		if (!this.storage) return;
		await this.storage.set({ id: viewKey, filters: {} });
	}

	async removeViewKeyFromStorage(viewKey) {
		if (!this.storage) return;
		await this.storage.clearAll(viewKey);
	}
}

export default new FiltersStorage();
