import { isEqual, merge } from 'lodash';
import { format, isValid } from 'date-fns';
import { isEmptyValue, isObject } from 'utils';
import { getCurrentHistory } from 'utils/location';
import { getQueryFiltersParameters } from 'utils/request';
import { isNumber } from 'utils/number';
import { datePresets } from 'utils/dates';
import { handleFetchData } from './fetch';

const history = getCurrentHistory();

export const getCurrentValue = option =>
	isObject(option) && 'value' in option ? option.value : option;

export const getCurrentBrowse = (state, currentBrowse) => state.browses[currentBrowse];

export const mapFilters = filters => {
	const getValue = value => {
		if (Array.isArray(value)) return value.map(val => getValue(val));

		return value instanceof Object && 'value' in value ? value.value : value;
	};

	return Object.entries(filters).reduce(
		(acc, [filterName, value]) => ({
			...acc,
			[filterName]: getValue(value)
		}),
		{}
	);
};

export const makeFetchDataParams = (state, page) => {
	const { sortBy } = state;

	const sortingUrl = () => {
		if (sortBy && sortBy.length === 1)
			return {
				sortBy: sortBy[0].name || sortBy[0].value,
				sortDirection: sortBy[0].initialSortDirection
			};

		const sortingSelected = {
			sortBy: [],
			sortDirection: []
		};

		if (sortBy && sortBy.length) {
			sortBy.forEach(item => {
				sortingSelected.sortBy.push(item.value || item.name);
				sortingSelected.sortDirection.push(item.initialSortDirection);
			});
		}

		return sortingSelected;
	};

	const sortAttrs = sortingUrl() || {};

	const { endpointParameters, currentViewData } = state.schema;

	const currentFilters = {
		filters: mapFilters(state.appliedFilters),
		page,
		...sortAttrs
	};

	const filters = getQueryFiltersParameters(endpointParameters, currentViewData, true);

	return merge(currentFilters, filters);
};

/**
 * History listener for refresh filters and data
 */
export const historyListener = currentBrowse => dispatch => {
	const currentPath = window.location.pathname;
	let currentLocationSearch = null;

	const unlisten = history.listen((location, action) => {
		const { pathname, search } = location;

		//	If page is changed call unlisten() for remove listener created.
		if (action === 'PUSH' && pathname !== currentPath) {
			currentLocationSearch = null;
			return unlisten();
		}

		// If change history by arrows in browser call handleFetchData() for update filters applyed and data
		if (action === 'POP' && pathname === currentPath && currentLocationSearch !== search) {
			dispatch(handleFetchData(false)(currentBrowse));
		}

		currentLocationSearch = search;
	});
};

export const hasSameArrayValues = (firstArrayValue, secondArrayValue) => {
	if (firstArrayValue.length !== secondArrayValue.length) return false;

	const sortedFirstArray = firstArrayValue.slice().sort();
	const sortedSecondArray = secondArrayValue.slice().sort();

	return sortedFirstArray.every((value, idx) => value === sortedSecondArray[idx]);
};

const parseValueToString = value => (isNumber(value) ? String(value) : value);

const filterIsDate = value => (value?.from && value?.to) || isValid(value);

const getDateValue = value => {
	const dateStart = format(new Date(value?.from), 'yyy-MM-dd');
	const dateEnd = format(new Date(value?.to), 'yyy-MM-dd');

	const selectedPreset = Object.values(datePresets).find(preset => {
		const presetStart = preset?.start.format('YYYY-MM-DD');
		const presetEnd = preset?.end.format('YYYY-MM-DD');

		return isEqual(presetStart, dateStart) && isEqual(presetEnd, dateEnd);
	});

	if (!selectedPreset) return value;

	const { startFilter, endFilter } = selectedPreset;

	const isDateRange = value?.from && value?.to;
	return isDateRange ? { from: startFilter, to: endFilter } : startFilter;
};

export const getValueFromFilter = filterValue => {
	if (filterIsDate(filterValue)) return getDateValue(filterValue);
	if (Array.isArray(filterValue)) {
		return filterValue.map(filter => parseValueToString(filter?.value));
	}
	return parseValueToString(filterValue?.value);
};

export const getFiltersValues = filters => {
	const filtersToSave = {};
	Object.entries(filters).forEach(([filterName, filterValue]) => {
		filtersToSave[filterName] =
			typeof filterValue === 'string' ? filterValue : getValueFromFilter(filterValue);
	});
	return filtersToSave;
};

const hasSameDateValues = (firstValue, secondValue) =>
	firstValue?.from === secondValue?.from && firstValue?.to === secondValue?.to;

export const checkFilterValues = (firstValue, secondValue) => {
	if (filterIsDate(firstValue)) return hasSameDateValues(firstValue, secondValue);
	return Array.isArray(firstValue)
		? hasSameArrayValues(firstValue, secondValue)
		: firstValue === secondValue;
};

const setExclusiveFilters = (sourceObj, objToValidate) => {
	if (!isObject(sourceObj) || !isObject(objToValidate)) return {};
	const exclusiveFilters = {};
	Object.keys(sourceObj).forEach(key => {
		if (!(key in objToValidate)) {
			exclusiveFilters[key] = sourceObj[key];
		}
	});
	return exclusiveFilters;
};

export const getFiltersValuesFromSources = (filtersFromUrl, defaultFilters, storagedFilters) => {
	if (!filtersFromUrl) return { filters: { ...defaultFilters, ...storagedFilters } };

	const filtersToSet = {};

	Object.keys(filtersFromUrl).forEach(filterName => {
		const filterValue = filtersFromUrl[filterName];

		const existsInDefault = filterName in defaultFilters;
		const existsInStorage = filterName in storagedFilters;

		if (existsInStorage || (!existsInStorage && !existsInDefault)) {
			filtersToSet[filterName] = filterValue;
			return;
		}

		if (existsInDefault) {
			const defaultValue = defaultFilters[filterName];
			const hasSameValueAsDefault = checkFilterValues(filterValue, defaultValue);

			filtersToSet[filterName] = !hasSameValueAsDefault ? filterValue : defaultValue;
		}
	});

	const exclusiveFiltersFromStorage = setExclusiveFilters(storagedFilters, filtersFromUrl);
	const exclusiveDefaultFilters = setExclusiveFilters(defaultFilters, filtersFromUrl);

	return {
		filters: {
			...filtersToSet,
			...exclusiveFiltersFromStorage,
			...exclusiveDefaultFilters
		}
	};
};

export const removeFiltersWithEmptyValues = filtersObj => {
	const nonEmptyFilters = {};
	Object.keys(filtersObj).forEach(key => {
		const value = filtersObj[key];
		if (!isEmptyValue(value)) nonEmptyFilters[key] = value;
	});
	return nonEmptyFilters;
};
