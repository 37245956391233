/* eslint-disable class-methods-use-this */

export default class IndexedDB {
	constructor(storageConfig) {
		this.isValidInstance = false;

		if (!storageConfig) {
			console.error('IndexedDB - invalid storage config');
			return;
		}

		const { name, version, options, transactionConfig } = storageConfig;

		if (!name || !version) {
			console.error("IndexedDB - Name or version doesn't provided");
			return;
		}

		this.init(name, version, options);
		this.transactionConfig = transactionConfig;
		this.isValidInstance = true;
	}

	static isAvailable() {
		if (!window.indexedDB) {
			// eslint-disable-next-line no-console
			console.warn('IndexedDb is not available in this browser');
			return false;
		}
		return true;
	}

	init(name, version, options) {
		this.openRequest = indexedDB.open(name, version);
		this.loadedDb = this.load(options);
	}

	load(options) {
		this.openRequest.onupgradeneeded = () => {
			// eslint-disable-next-line no-console
			console.log('IndexedDB - loading database...');
			const { objectStores = [] } = options || {};
			this.createObjectStores(objectStores);
		};

		this.openRequest.onerror = event => {
			// eslint-disable-next-line no-console
			console.error(`IndexedDB error: ${event.target.errorCode}`);
		};

		this.openRequest.onsuccess = event => {
			// eslint-disable-next-line no-console
			console.log('IndexedDB - database successfully loaded');
			this.db = event?.target?.result;
		};
	}

	getDB() {
		if (!this.db) this.db = this.loadedDb;
		return this.db;
	}

	createObjectStores(objectStores) {
		if (!objectStores.length) return;
		const db = this.openRequest.result;
		objectStores.forEach(
			({ name, config: { keyPath = 'id', autoIncrement = false, indexes = [] } }) => {
				const store = db.createObjectStore(name, { keyPath, autoIncrement });
				this.createIndexes(store, indexes);
			}
		);
	}

	createIndexes(store, indexes) {
		if (!indexes || !indexes.length) return;

		indexes.forEach(({ name, tags, unique }) => {
			store.createIndex(name, [...tags], { unique });
		});
	}

	createTransaction(objectStoreName, mode = 'readwrite') {
		if (!objectStoreName) throw new Error('IndexedDB - "objectStoreName" is required');
		return this.getDB().transaction(objectStoreName, mode);
	}

	getObjectStore() {
		const { objectStoreName, mode } = this.transactionConfig || {};
		if (!objectStoreName)
			throw new Error('IndexedDB - No "objectStoreName" specified in transactionConfig');
		const transaction = this.createTransaction(objectStoreName, mode);
		return transaction.objectStore(objectStoreName);
	}

	get(viewKey) {
		return new Promise((resolve, reject) => {
			const store = this.getObjectStore();

			if (!viewKey) throw new Error('IndexedDB - Empty view key');
			if (!store) throw new Error('IndexedDB - No store found');

			const data = store.get(viewKey);

			data.onsuccess = () => {
				const result = data?.result;
				resolve(result);
			};

			data.onerror = err => {
				// eslint-disable-next-line prefer-promise-reject-errors
				reject(`IndexedDB - Error on get data ${err}`);
			};
		});
	}

	set(dataToSave) {
		return new Promise((resolve, reject) => {
			const store = this.getObjectStore();

			if (!dataToSave) throw new Error('IndexedDB - No data to save');
			if (!store) throw new Error('IndexedDB - No store found');

			const request = store.put(dataToSave);

			request.onsuccess = () => {
				const result = request?.result;
				resolve(result);
			};

			request.onerror = event => {
				reject(event.target.errorCode);
			};
		});
	}

	clearAll(viewKey) {
		return new Promise((resolve, reject) => {
			const store = this.getObjectStore();

			if (!viewKey) throw new Error('IndexedDB - Empty view key');
			if (!store) throw new Error('IndexedDB - No store found');

			const request = store.delete(viewKey);

			request.onsuccess = () => {
				const result = request?.result;
				resolve(result);
			};

			request.onerror = event => {
				reject(event.target.errorCode);
			};
		});
	}

	close() {
		const db = this.loadedDb;
		db.close();
	}

	deleteDatabase(name) {
		if (!name) throw new Error('IndexedDB - Database name is required to delete');
		indexedDB.deleteDatabase(name);
	}
}
