/* eslint-disable class-methods-use-this */
import { format as datesFormat } from 'date-fns';
import { MOMENT_DICTIONARY } from './constants';
import { LOCALES } from './locales';
import isValidDate from './helpers/isValid';

/**
 * A class to handle date formatting and validation with support for multiple locales
 */
class DateHandler {
	/**
	 * Creates an instance of DateHandler
	 * @param {Object} config - Configuration options
	 * @param {"en" | "es" | "pt-BR" | "pt"} [config.locale="en"] - The initial locale
	 * @example
	 * const Dates = new DateHandler({ locale: 'es' });
	 */
	constructor(language = 'en') {
		if (DateHandler.instance) return DateHandler.instance;

		this.setLocale(language);

		DateHandler.instance = this;
	}

	/**
	 * Updates the locale dynamically
	 * @param {"en" | "es" | "pt-BR" | "pt"} newLocale - The new locale to apply
	 * @example
	 * const Dates = new DateHandler({ locale: 'en' });
	 * Dates.setLocale('es'); // Switches locale to Spanish
	 */
	setLocale(language) {
		this.language = LOCALES[language] ? language : 'en';
		this.locale = LOCALES[this.language];
	}

	/**
	 * Validates if a given date is valid
	 * @param {Date|string} date - The date to validate
	 * @returns {boolean} True if the date is valid, otherwise false
	 * @example
	 * const Dates = new DateHandler({ locale: 'es' });
	 * Dates.isValid('2024-10-04T21:26:33.801Z'); // true
	 * Dates.isValid('invalid date'); // false
	 */
	isValid(date) {
		return isValidDate(date);
	}

	/**
	 * Formats a date or a range of dates based on the current locale
	 * @param {Date | string} date - The date(s) to format
	 * @param {string} [format="P"] - The format string (supports moment.js and date-fns styles)
	 * @param {Object} [options={}] - Additional formatting options
	 * @returns {string | null} The formatted date string, or null if formatting fails
	 * @example
	 * const Dates = new DateHandler({ locale: 'es' });
	 * Dates.format('2024-11-09T11:00:00.000Z', 'dd/MM/yyyy HH:mm'); // '09/11/2024 11:00'
	 */
	format(date, formatString = 'dd/MM/yyyy HH:mm') {
		if (!date) return null;
		try {
			if (!this.isValid(date)) throw new Error('Invalid date');

			const mappedFormat = MOMENT_DICTIONARY[formatString] || formatString;
			const parsedDate = new Date(date);

			return datesFormat(parsedDate, mappedFormat, { locale: this.locale });
		} catch (error) {
			console.error(error);
			throw error;
		}
	}
}

const DateHandlerService = new DateHandler();

export default DateHandlerService;
