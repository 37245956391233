import React, { useState, useEffect } from 'react';
import translationHOC from 'hocs/translationHOC';
import PropTypes from 'prop-types';
import Select from 'components/Select';
import DateHandler from 'libs/Dates';
import { useDevices } from 'utils/hooks';
import { callRequest } from 'utils/request';
import styled from './styles';

const LanguageSelector = ({ isAuthWrapper, t, i18n, isProfileDetailOpen }) => {
	const { language: currentLanguage } = i18n;

	if (!currentLanguage) return null;

	const { onlyDesktop } = useDevices();

	const [languages, setLanguages] = useState([]);
	const [selectedLanguage, changeLanguage] = useState(currentLanguage);
	const [isLoading, setLoading] = useState(false);
	const [languagesWereGot, setLanguagesGot] = useState(false);

	const formatLanguageLabel = selectedLanguageFullCode => {
		const language = languages.find(
			eachLanguage => eachLanguage.fullCode === selectedLanguageFullCode
		);

		return language && language.name;
	};

	const getLanguages = async () => {
		const source = {
			service: 'i18n',
			namespace: 'language',
			method: 'list'
		};

		const filters = {
			filters: { status: 'active' },
			sortBy: 'name',
			sortDirection: 'asc'
		};

		setLoading(true);

		const data = await callRequest(source, filters);

		if (data) setLanguages(data);

		setLoading(false);
	};

	const processGetLanguages = () => {
		if (!isProfileDetailOpen || languagesWereGot) return;

		getLanguages();
		setLanguagesGot(true);
	};

	const setSelectedLanguage = data => {
		const source = {
			service: 'id',
			namespace: 'account',
			method: 'save'
		};

		callRequest(source, { lang: data });
	};

	useEffect(
		() => {
			processGetLanguages();
		},
		[isProfileDetailOpen]
	);

	//  used in login
	useEffect(() => {
		if (isAuthWrapper) getLanguages();
	}, []);

	useEffect(
		() => {
			changeLanguage(currentLanguage);
			DateHandler.setLocale(currentLanguage);
		},
		[currentLanguage]
	);

	const regex = /^[^(]+/;
	const options = languages.map(language => ({
		label: language.name,
		rawLabel: language.name.match(regex)[0].trim(), // for allow filter options
		value: language.fullCode
	}));

	const handleLanguageSelection = (option = {}) => {
		i18n.changeLanguage(option.value);
		if (isAuthWrapper) localStorage.setItem('preselectedLanguage', option.value);
		if (!isAuthWrapper) {
			localStorage.setItem('selectedLanguage', option.value);
			setSelectedLanguage(option.value);
		}
	};

	const noOptionsMessage = isLoading ? 'common.word.loading' : 'views.search.resultsNotFound';

	return (
		<styled.LanguageSelector isAuthWrapper={isAuthWrapper} onlyDesktop={onlyDesktop}>
			<Select
				canClear={false}
				hasFloatingLabel={false}
				isLoading={!options.length}
				isSearchable
				onChange={option => handleLanguageSelection(option)}
				options={options}
				rounded
				value={{ label: formatLanguageLabel(selectedLanguage), value: selectedLanguage }}
				noOptionsMessage={() => t(noOptionsMessage)}
			/>
		</styled.LanguageSelector>
	);
};

LanguageSelector.propTypes = {
	isAuthWrapper: PropTypes.bool,
	i18n: PropTypes.shape({}),
	isProfileDetailOpen: PropTypes.bool
};

export default translationHOC(LanguageSelector);
